import { Typography } from 'antd';
import { Helmet } from 'react-helmet';
import tumbnailImage from '../../assets/images/ImageThumbnailAppSitzplanDigital.png'
import { useSelector } from 'react-redux';
const { Title, Paragraph, Text } = Typography;

export default function HomepageComponent() {
  // const students = useSelector(state => state.students);




// if (students[0] == null) {
    return (
      <>

      <Helmet>
        <meta charSet="utf-8" />
        <html lang="de" amp />
        <title>Sitzpläne und Gruppen erstellen | datenschutzkonform, kostenlos</title>
        <link rel="icon" href="https://sitzplandigital.de/favicon.svg" />
        <base target="_blank" href="https://sitzplandigital.de" />  

        <meta name="description" content="Digitale Sitzpläne für den Unterricht online erstellen und teilen, Schüler oder Gruppen zufällig auswählen | Web-App" />
        <meta property="og:type" content="homepage" />

        {/* <link rel="canonical" href="https://sitzplandigital.de" /> */}


        <meta name="theme-color" content="#00b96b" />
        <meta name="og:title" content="SitzplanDigital.de"/>
        <meta name="og:type" content="webapp"/>
        <meta name="og:url" content="https://sitzplandigital.de"/>
        <meta name="og:image" content="https://stizplandigital.de/ImageThumbnailAppSitzplanDigital.png"/>
        <meta name="og:site_name" content="SitzplanDigital.de"/>
        <meta name="og:description" content="Digitale Sitzpläne für den Unterricht online erstellen und teilen, Schüler oder Gruppen zufällig auswählen | Web-App"/>

        <meta name="keywords" content="free, kostenlos, werbefrei, sitzplan, online, generator, zufall"/>
        <meta name="description" content="Digitale Sitzpläne für den Unterricht online erstellen und teilen, Schüler oder Gruppen zufällig auswählen | Web-App"/>
        <meta name="subject" content="Online Sitzplan Verwaltung"/>
        <meta name="copyright"content="Carlo von Reyher"/>
        <meta name="language" content="de"/>
        <meta name="robots" content="index,follow" />
        <meta name="revised" content="03.04.2023" />
        <meta name="topic" content="Online Sitzplatzverwaltung"/>
        <meta name="summary" content="Unsere App SitzplanDigital.de erleichtert die Erstellung von Sitzplänen für Schulen und Veranstaltungen durch automatische Gruppenerstellung und Zufallsgenerator."/>
        <meta name="Classification" content="App"/>
        <meta name="author" content="Carlo von Reyher"/>
        <meta name="designer" content="Carlo von Reyher"/>
        <meta name="copyright" content="Carlo von Reyher"/>
        <meta name="reply-to" content="hey@vonreyher.com"/>
        <meta name="owner" content="Carlo von Reyher"/>
        {/* <meta name="url" content="https://sitzplandigital.de"/> */}
        {/* <meta name="identifier-URL" content="https://sitzplandigital.de"/> */}
        <meta name="coverage" content="Worldwide"/>
        <meta name="distribution" content="Global"></meta>


        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "SitzplanDigital.de",
        "url": "https://sitzplandigital.de",
        "sameAs": [
          "https://twitter.com/c_v_reyher",
          "https://www.sitzplandigital.de"
        ],
        "description": "Digitale Sitzpläne für den Unterricht online erstellen und teilen, Schüler oder Gruppen zufällig auswählen | Web-App",
        "alternateName": "Sitzplan Digital",
        "audience": {
          "@type": "Audience",
          "audienceType": [
            "Schüler",
            "Lehrer",
            "Teacher"
          ]
        },
        "author": {
          "@type": "Person",
          "name": "Carlo von Reyher",
          "url": "https://vonreyher.com",
          "sameAs": "https://www.wikidata.org/wiki/Q99570403"
        },
        "contributor": {
          "@type": "Organization",
          "name": "vonReyher.Media",
          "url": "https://vonreyher.media"
        },
        "copyrightHolder": {
          "@type": "Person",
          "name": "Carlo von Reyher"
        },
        "copyrightYear": "2023",
        "funder": {
          "@type": "Person",
          "name": "Thomas Roßwog"
        },
        "inLanguage": "German",
        "text": "Erstellen Sie schnell und einfach Sitzpläne für Ihre Veranstaltungen. Perfekt für Lehrer, Eventplaner und alle, die Sitzpläne benötigen.",
        "thumbnailUrl": "https://stizplandigital.de/ImageThumbnailAppSitzplanDigital.png",
        "version": "1.0.0"
      })}
        </script>
      </Helmet>
      <Typography>
        <Title level={3}>Willkommen auf SitzplanDigital.de</Title>

        <Paragraph>
        <font size="4">
           
          <ul> 
          <li>Sitzpläne einfach digital erstellen und teilen</li>
            <li>Schüler und Arbeitsgruppen zufällig auswählen</li>
            <li>keine Anmeldung, kostenlos und ohne Werbung</li>
          </ul>
          </font>
        </Paragraph>

        {/* <Title level={5}>Was die WebApp Sitzplan Digital?</Title>
        <Paragraph>
        SitzplanDigital.de ist eine Webanwendung, die speziell für Lehrer entwickelt wurde, um ihre Schüler im Klassenraum visuell zu organisieren und den Unterricht effektiver zu gestalten. Unsere Anwendung bietet ein einfaches Drag-and-Drop-System, mit dem Lehrer Schüler einfach anordnen und verschiedene Tools wie den Zufallsgenerator oder den Gruppenersteller nutzen können, um ihren Unterricht zu optimieren. Darüber hinaus können Lehrer ihre Sitzpläne als PDF exportieren, um sie später zu drucken oder mit anderen Lehrern zu teilen. Egal, ob Sie in einer Grundschule, einer weiterführenden Schule oder einer Universität arbeiten, SitzplanDigital.de ist die perfekte Lösung, um Ihren Unterricht zu verbessern und Ihre Schüler effektiver zu organisieren. Probieren Sie es jetzt aus und erleichtern Sie sich das Leben!
        </Paragraph> */}
      
        <Title level={3}>Anleitung</Title>
        
        <div style={{padding:'56.25% 0 0 0', position:'relative'}}><iframe title="1" src="https://vimeo.com/showcase/10233403/embed" allowFullScreen frameBorder={0} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} /></div>
  
        <Title level={3}>Funktionsprinzip und Datenschutz</Title>
       
        
        <Paragraph>
        Beim Erstellen eines Sitzplans werden die eingegebenen Namen und zugehörigen Sitzplätze als Parameter-Liste in der URL angelegt. Die URL ist sozusagen der Speicherort der eingegebenen Daten. Eine dauerhafte Speicherung der Sitzpläne auf unserem Server ist nicht vorgesehen.
         </Paragraph>
        <Paragraph>
        Der erstellte Sitzplan kann lokal im Browser als Lesezeichen gespeichert oder über eine datensichere Kommunikation mit anderen als Link geteilt werden. Bei nachträglichen Änderungen des Sitzplans, ändert sich auch wieder die URL, die dann erneut gespeichert oder weitergegeben werden kann. 
        </Paragraph>
        <Paragraph>
        Der Server speichert vorübergehend sogenannte Server-Log-Dateien in denen die Aktivitäten auf der Website protokolliert werden. Die Server-Log-Dateien beinhalten auch die URL mit den Namen und der IP-Adresse. Diese personenbezogenen Daten werden DSGVO-konform nach 7 Tagen automatisch gelöscht (siehe Datenschutzerklärung).
        </Paragraph>
       
      </Typography>
      </>
    )
//    }
// else{
//    return (
//      <Title level={2}>Übersicht:</Title>
//  
//    )
//  }
 
}
