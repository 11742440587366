import React from 'react'
import { Modal, Form, Input, Button } from 'antd';

export default function Popup(props) {
    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        // Send Data one Component up
        props.update(values);
        handleCancel();
    }

    const handleCancel = () => {
        props.openclose(false)
        form.resetFields()
    };
      

  return (
    <div>
        <Modal
            title={"Momentan wird " + props.data.name + " bearbeitet."}
            centered
            open={props.open}
            onCancel={() => handleCancel()}
            footer={[
                <Button form="changedata" key="submit" htmlType="submit">
                    Speichern
                </Button>
                ]}
        >
            <Form
            form={form}
            name="changedata"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
            onFinish={handleSubmit}
            >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Bitte geben sie einen Namen ein!' }]}
            >
                <Input placeholder={props.data.name}/>
            </Form.Item>
            </Form>
        </Modal>
    </div>
  )
}
