import { configureStore } from '@reduxjs/toolkit'


// Import all the Different Reducer
import studentsReducer from './features/students/students.js'
import klassenraumReducer from './features/klassenraum/klassenraum.js'


export default configureStore({
  reducer: {
    students: studentsReducer,
    klassenraum: klassenraumReducer,
  },
  devTools: true,
  // Note: you can include options in the argument of the getDefaultMiddleware function call.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})


// Add here the Reducer
