import { Alert, Button, Card, Col, Divider, InputNumber, Row, Slider, Space, Switch } from 'antd';
import Paragraph from 'antd/es/skeleton/Paragraph';
import Title from 'antd/es/skeleton/Title';
import Typography from 'antd/es/typography/Typography';
import React, { createContext, useContext, useState } from 'react'
import { Provider, useSelector } from 'react-redux';
import { SettingOutlined, TeamOutlined, AppstoreOutlined } from '@ant-design/icons';
import './style.css';




const ArbeitsGruppenContext = createContext({});


export default function ArbeitsgruppenView() {
    const students = useSelector(state => state.students);

    const [arbeitsGruppenData, setArbeitsGruppenData] = useState(initialState);


    if (students.length < 3) {
        return (
            <>
            <Divider></Divider>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <Alert
                style={{width: '70%'}}
                message="Achtung"
                description="Zurzeit ist keine Gruppenerstellung möglich. Anscheinend sind momentan nicht genügend Schülerdaten vorhanden. Um dies zu ändern fügen Sie bitte mindestens drei Schüler zu dieser Klasse hinzu."
                type="warning"
                showIcon
                />
             </div>
            </>
          )
    }else{
        return (
            <ArbeitsGruppenContext.Provider value={{arbeitsGruppenData, setArbeitsGruppenData}}>
                {arbeitsGruppenData[0] !== undefined ? <ShowGroups/> : <CreateGroups/>}
            </ArbeitsGruppenContext.Provider>
          )
    }
  
}




function CreateGroups() {
    const data = useContext(ArbeitsGruppenContext);
    const students = useSelector(state => state.students);
    const [gruppenAnzahl, setGruppenAnzahl] = useState(2);
    const [StudentsProGruppe, setStudentsProGruppe] = useState(2)
    const { Title, Paragraph, Text } = Typography;


    function createGroupsDataWithStudentsPerGroup (StudentsProGruppe, students) {
        var internalArrayStudents = Array.from(students);
        var rest = students.length%StudentsProGruppe;
        var gruppenAnzahl = (students.length-rest)/StudentsProGruppe;

        const newData = [];

        console.log(StudentsProGruppe, rest, gruppenAnzahl, internalArrayStudents);


        for (let i = 0; i <= gruppenAnzahl-1; i++) {
            newData[i]={name: "Gruppe " + i, schueler: []};
            for (let e = 1; e < StudentsProGruppe+1; e++) {
                // Choose Random Student from internalArrayStudents
                var randomIndex = Math.floor(Math.random()*internalArrayStudents.length);
                var randomItem = internalArrayStudents[randomIndex];
                // Add to Data
                newData[i].schueler.push({name: randomItem.name, SitzplatzId: randomItem.SitzplatzId});
                // remove Student from internalArrayStudents
                internalArrayStudents.splice(randomIndex, 1);
            }
            console.log(newData);
        }
        if (rest > 0) {
            for (let g = 0; g < rest; g++) {
                // Choose Random Student from internalArrayStudents
                var randomIndex = Math.floor(Math.random()*internalArrayStudents.length);
                var randomItem = internalArrayStudents[randomIndex];
                // Add to Data
                newData[g].schueler.push({name: randomItem.name, SitzplatzId: randomItem.SitzplatzId});
                // remove Student from internalArrayStudents
                internalArrayStudents.splice(randomIndex, 1);
                }
        }



        data.setArbeitsGruppenData(newData);

    }



    function createGroupsData(gruppenAnzahl, students) {
        var internalArrayStudents = Array.from(students);
        
        const rest = students.length%gruppenAnzahl;
        const PersonenProGruppe = (students.length-rest)/gruppenAnzahl;
        const newData = [];

        for (let i = 0; i <= gruppenAnzahl-1; i++) {
            newData[i]={name: "Gruppe " + i, schueler: []};
            for (let e = 1; e < PersonenProGruppe+1; e++) {
                // Choose Random Student from internalArrayStudents
                var randomIndex = Math.floor(Math.random()*internalArrayStudents.length);
                var randomItem = internalArrayStudents[randomIndex];
                // Add to Data
                newData[i].schueler.push({name: randomItem.name, SitzplatzId: randomItem.SitzplatzId});
                // remove Student from internalArrayStudents
                internalArrayStudents.splice(randomIndex, 1);
            }
        }
        if (rest > 0) {
            for (let g = 0; g < rest; g++) {
                // Choose Random Student from internalArrayStudents
                var randomIndex = Math.floor(Math.random()*internalArrayStudents.length);
                var randomItem = internalArrayStudents[randomIndex];
                // Add to Data
                newData[g].schueler.push({name: randomItem.name, SitzplatzId: randomItem.SitzplatzId});
                // remove Student from internalArrayStudents
                internalArrayStudents.splice(randomIndex, 1);
                }
        }


        data.setArbeitsGruppenData(newData);
    }

    return(
        <>


        <Divider/>
        <Title level={5} strong>Übersicht:</Title>
        <Text>Momentan sind in dieser Klasse {students.length} Schüler, Sie können hier zufällige Schüler Gruppen erstellen.</Text>
        <Text> Dazu können Sie entweder die Schüleranzahl auswählen oder die Gruppenanzahl. Die Maximale Anzahl an Gruppen die Sie erstellen können ist momentan {Math.floor(students.length/2)}.</Text>

  
        <Divider style={{marginTop: '50px'}}/>
        <Row gutter={{ xs: 12, sm: 12, md: 24, lg: 24 }}>
            <Col md={{span:12}} xs={{span: 24}}>
                <Title level={4} strong>Gruppenanzahl bestimmen:</Title>
                <br />
                <InputNumber min={2} max={Math.floor(students.length/2)} defaultValue={Math.floor(students.length/2)} onChange={(value) => {setGruppenAnzahl(value)}} /> <br />
                {students.length%gruppenAnzahl>0 ? <Alert showIcon style={{margin: '10px 0px'}} message="Diese Aufteilung geht nicht auf!" type="warning"/> : <Text style={{display: 'block', margin: '25px 0px'}}>Super, in jeder Gruppe sind gleich viele Schüler.</Text>}
                <br />
                <Space direction="horizontal" style={{marginTop: '5px'}}>
                    <Button icon={<TeamOutlined />} onClick={() => {createGroupsData(gruppenAnzahl, students)}}>{gruppenAnzahl} Gruppen erstellen</Button>
                </Space>
            </Col>
            <Col  md={{span:12}} xs={{span: 24}}>
                <Title level={4} strong>Schüleranzahl bestimmen:</Title>
                <br></br>
                <InputNumber min={2} max={Math.floor(students.length/2)} defaultValue={3} onChange={(value) => {setStudentsProGruppe(value)}} /> <br />
                <Text style={{display: 'block', margin: '25px 0px'}}>Es werden {(students.length-(students.length%StudentsProGruppe))/StudentsProGruppe} Gruppen erstellt.</Text> <br />
                <Space direction="horizontal" style={{marginTop: '5px'}}>
                    <Button icon={<AppstoreOutlined />} onClick={() => {createGroupsDataWithStudentsPerGroup(StudentsProGruppe, students)}}>{StudentsProGruppe}er Gruppen erstellen</Button>
                </Space>
            </Col>
        </Row>
        </>
    )
}



function ShowGroups (props){
    const data = useContext(ArbeitsGruppenContext);
    const { Title, Paragraph, Text } = Typography;

    console.log(data);

    const Cards = data.arbeitsGruppenData.map((element) => {
        console.log(element);
        const schueler = element.schueler.map((element) => {
            return(<li><Text>{element.name}</Text></li>)
        })
        return(
            <Card title={element.name + " - "+ element.schueler[0].name } style={{ width: 300 }}>
                <Text strong >Mitglieder:</Text>
                <Paragraph>
                    <ul>
                        {schueler}
                    </ul>
                </Paragraph>
            </Card>
        );
    })



    return(
        <>
        <Divider/>
        <Space direction="horizontal" size={30} style={{width: '100%', justifyContent: 'left', flexWrap: 'wrap', marginTop: '50px'}}>
            {Cards}
        </Space>
        <Space direction="horizontal" align="start" style={{marginTop: '40px', width: '100%',  justifyContent: 'center'}}>
            <Button icon={<SettingOutlined />} onClick={() => {data.setArbeitsGruppenData({})}}>Einstellungen</Button>
        </Space>
        </>
    );
    
}






const initialState = [
    // {
    //     name: "Gruppe1",
    //     schueler: [
    //     {
    //         name: 'Tobias',
    //         SitzplatzId: 12
    //     },
    //     {
    //         name: 'David',
    //         SitzplatzId: 9
    //     },
    //     {
    //         name: 'Carlo',
    //         SitzplatzId: 10
    //     }
    //     ]
    // },
    // {
    //     name: "Gruppe3",
    //     schueler: [
    //     {
    //         name: 'Test',
    //         SitzplatzId: 12
    //     },
    //     {
    //         name: 'David',
    //         SitzplatzId: 9
    //     },
    //     {
    //         name: 'Carlo',
    //         SitzplatzId: 10
    //     }
    //     ]
    // },
    // {
    //     name: "Gruppe4",
    //     schueler: [
    //     {
    //         name: 'Tom',
    //         SitzplatzId: 12
    //     },
    //     {
    //         name: 'David',
    //         SitzplatzId: 9
    //     },
    //     {
    //         name: 'Carlo',
    //         SitzplatzId: 10
    //     }
    //     ]
    // },
    // {
    //     name: "Gruppe4",
    //     schueler: [
    //     {
    //         name: 'Tom',
    //         SitzplatzId: 12
    //     },
    //     {
    //         name: 'David',
    //         SitzplatzId: 9
    //     },
    //     {
    //         name: 'Carlo',
    //         SitzplatzId: 10
    //     }
    //     ]
    // }
]