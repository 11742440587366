


export const Routes = {
    BaseRoute: '/',
    SchulerView: '/schueler',
    SitzplanView: '/sitzplan',
    AboutView: '/ueber',
    GruppenErstellerView: '/gruppen-erstellen',
    ZufallsgeneratorView: '/zufallsgenerator',
    ExportierenView: '/sitzplan-exportieren'
}