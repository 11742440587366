import { Button, Divider, message, notification, Card, Typography, Row, Col, Spin, Slider } from 'antd';
import React, { useState } from 'react';
import {
    ReloadOutlined
  } from '@ant-design/icons';
import '../sitzordnung/tablestyle.css'
import { useSelector } from 'react-redux';
import './index.css';

import Helmet from 'react-helmet';




export default function ZufallsgeneratorView(props) {

  const state = useSelector(state => state);

  const studentsData = state.students;
  const layoutData = state.klassenraum;

  

  const [randomChoice, setRandomChoice] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [speed, setSpeed] = useState(100)




  // Funktion um zu schauen, ob es einen Studenten bei einer bestimmten Nummer sitzt
  function TestIfStudent(seatNumber) {

    const result = studentsData.filter((element) => element.SitzplatzId === seatNumber)

    
    if (result[0] != null) {
        return {isCurrentlyStudentOrNot: true, displayname: result[0].name} 
    }
    if (result[1] != null){
        throw new Error('Oh, es scheint als ob die Tabelle nicht Richtig gerendert wurde.')
    }

    return {isCurrentlyStudentOrNot: false}
  }





  const Table = (props) => {
    if (randomChoice.SitzplatzId === props.CurrentField) {
      return (
      <div key={props.displayname} className="Tisch" id={"#s" + props.CurrentField} style={{backgroundColor: '#a2ebbf' }}>{props.displayname}</div>
      )
    }
    return (
      <div key={props.displayname} className="Tisch" id={"#s" + props.CurrentField}>{props.displayname}</div>
    )
  }
  

  const CustomTabelle = () => {
    return <table className='StudentTableRandom'>{[...Array(layoutData.y)].map((e, yn) => {
      return <tr key={yn} id={yn}>
        {
            [...Array(layoutData.x)].map((e, xn) => {
                var CurrentField = yn*layoutData.x+xn; // Calculate the current Field Number

                // test if in this Feld is an Item
                var hasSeatOnLoad = TestIfStudent(CurrentField);

                return <td id={xn+yn} x={xn} y={yn} key={yn + xn}  number={CurrentField} className="Feld">
                    {
                        // Erstellen der Studenten Tische mit einer div, wenn ein Student dort ist.
                        hasSeatOnLoad.isCurrentlyStudentOrNot ?  <Table CurrentField={CurrentField} displayname={hasSeatOnLoad.displayname}/>: <></>                              
                    }
                </td>
            })
        }
      </tr>
    })}</table>
  }

  function getRandomTable(StudentsDataArray) {
    return StudentsDataArray[Math.floor(Math.random()*StudentsDataArray.length)];
  }

  const RandomTableAnimation = () =>  {
    try {

        // Test if there are Students 
        if (studentsData.length < 2) {
            throw Error('Nicht genügend Schüler!');
        }

         setIsLoading(true);

        var ausgewählterSchueler = getRandomTable(studentsData);
        

        setRandomChoice(ausgewählterSchueler);
        // Change Aussehen SitzplatzId
        new Promise((resolve, reject) => {
            for (let i = 1; i < 30; i++) {
                setTimeout(function() {
                  var ausgewählterSchueler = getRandomTable(studentsData);
                  setRandomChoice(ausgewählterSchueler);
                  if (i === 29) {
                    resolve('');
                  }
                }, speed * i);
            }
        }).then((data) => {
            setIsLoading(false);
            message.success('Schüler ausgewählt!');
        })

    } catch (error) {
        notification.error({message: 'Fehler: ' + error.message})
    }
  }


  return (
    <>
    <Helmet>
        <title>Schüler zufällig auswählen</title>

      <meta name="description" content="Hier kann man zufällig einen Schüler von der Website auswählen lassen." />
      <meta property="og:type" content="table" />
      </Helmet>
    <div className="">
        {isLoading || randomChoice.SitzplatzId!= null ? <><Divider/><CustomTabelle></CustomTabelle><Divider/></>: <></>} 
      </div>
      <Row>
        <Col span={16} offset={2}>
          <Anzeige choice={randomChoice} handleGetRandomTable={RandomTableAnimation} isLoading={isLoading} setSpeed={setSpeed} Speed={speed}/>
        </Col>
      </Row>
    </>
  )
}


 function Anzeige(props) {
  const { Title, Paragraph } = Typography;
  const formatter = (value) => `${value} Dauer`;
  if(props.choice.SitzplatzId === undefined){
    return (
      <>
      <Spin spinning={props.isLoading}>
              
              
              <Button type="primary" icon={< ReloadOutlined spinning={props.isLoading}/>}  onClick={() => {props.handleGetRandomTable()}}>Schüler zufällig auswählen</Button>
              <Divider></Divider>
              <Paragraph>Dauer Einstellen:</Paragraph>
              <Slider tooltip={{formatter,}} onAfterChange={(value) => props.setSpeed(value)} min={10} max={300} defaultValue={props.Speed}/>
              
      </Spin>
      </>
    )
  }else{
    return (
      <Spin spinning={props.isLoading}>
        <Card title={""  + props.choice.name + " wurde zufällig ausgewählt."} bordered={true}>
           
            <Button type="secondary" icon={<ReloadOutlined />}  onClick={() => {props.handleGetRandomTable()}}>Zufallswahl wiederholen.</Button>
        </Card>
      </Spin>
    )
  }
  
}








