import { generatePDF } from "./generatePDF"; // Passe den Pfad entsprechend deiner Dateistruktur an

import React, { useState } from "react";
import { Button, Divider, Form, Steps, Typography, message, Input } from "antd";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
const { Title, Paragraph, Text, Link } = Typography;

export default function ExportToPDF() {
  // Get the current URL
  var currentUrl = window.location.href;

  // Replace "sitzplan-exportieren" with "zufallsgenerator"
  var newUrl = currentUrl.replace("sitzplan-exportieren", "zufallsgenerator");

  const currentLink = newUrl;

  const [title, setTitle] = useState("");
  const [version, setVersion] = useState("");

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleVersionChange = (e) => {
    setVersion(e.target.value);
  };

  const state = useSelector((state) => state);

  const studentsData = state.students;
  const layoutData = state.klassenraum;

  const handleGeneratePDF = async () => {
    try {
      const pdfBytes = await generatePDF(
        studentsData,
        layoutData,
        currentLink,
        title,
        version
      );

      // Hier kannst du die PDF-Bytes verwenden, um die PDF-Datei zu speichern oder anzuzeigen
      // Zum Beispiel: Speichern des PDFs im lokalen Speicher und es dem Benutzer zum Download anbieten
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "schuelerliste.pdf";
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Fehler beim Generieren des PDFs:", error);
    }
  };

  const handleSubmit = (e) => {
    // Füge hier den Code für das Absenden des Formulars hinzu, z.B. das Speichern der Daten.
    handleGeneratePDF();
  };

  return (
    <>

<Helmet>
        <meta charSet="utf-8" />
        <html lang="de" amp />
        <title>Sitzpläne als PDF Exportieren | datenschutzkonform & kostenlos</title>
        <link rel="icon" href="https://sitzplandigital.de/favicon.svg" />
        <base target="_blank" href="https://sitzplandigital.de" />  

        <meta name="description" content="Mit dieser Web-App können digitale Sitzpläne erstellt und geteilt werden. Hier können sie den Sitzplan als PDF Exportieren." />
        <meta property="og:type" content="homepage" />

        {/* <link rel="canonical" href="https://sitzplandigital.de" /> */}


        <meta name="theme-color" content="#00b96b" />
        <meta name="og:title" content="SitzplanDigital.de"/>
        <meta name="og:type" content="webapp"/>
        <meta name="og:url" content="https://sitzplandigital.de"/>
        <meta name="og:image" content="https://stizplandigital.de/ImageThumbnailAppSitzplanDigital.png"/>
        <meta name="og:site_name" content="SitzplanDigital.de"/>
        <meta name="og:description" content="Exportiere jetzt diesen Sitzplan als PDF. Mit dieser Web-App können digitale Sitzpläne erstellt und geteilt werden. Einzelne Schüler oder Gruppen lassen sich zufällig auswählen."/>

        <meta name="keywords" content="free, kostenlos, werbefrei, sitzplan, online, generator, zufall"/>
        <meta name="description" content="Mit dieser Web-App können digitale Sitzpläne erstellt und geteilt werden. Einzelne Schüler oder Gruppen lassen sich zufällig auswählen."/>
        <meta name="subject" content="Online Sitzplan Verwaltung"/>
        <meta name="copyright"content="Carlo von Reyher"/>
        <meta name="language" content="de"/>
        <meta name="robots" content="index,follow" />
        <meta name="revised" content="03.04.2023" />
        <meta name="topic" content="Online Sitzplatzverwaltung"/>
        <meta name="summary" content="Unsere App SitzplanDigital.de erleichtert die Erstellung von Sitzplänen für Schulen und Veranstaltungen durch automatische Gruppenerstellung und Zufallsgenerator."/>
        <meta name="Classification" content="App"/>
        <meta name="author" content="Carlo von Reyher, hey@vonreyher.com"/>
        <meta name="designer" content="Carlo von Reyher"/>
        <meta name="copyright" content="Carlo von Reyher"/>
        <meta name="reply-to" content="hey@vonreyher.com"/>
        <meta name="owner" content="Carlo von Reyher"/>
        {/* <meta name="url" content="https://sitzplandigital.de"/> */}
        {/* <meta name="identifier-URL" content="https://sitzplandigital.de"/> */}
        <meta name="coverage" content="Worldwide"/>
        <meta name="distribution" content="Global"></meta>


        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "SitzplanDigital.de",
        "url": "https://sitzplandigital.de",
        "sameAs": [
          "https://twitter.com/c_v_reyher",
          "https://www.sitzplandigital.de"
        ],
        "description": "Erstellen Sie schnell und einfach Sitzpläne für Ihre Veranstaltungen. Perfekt für Lehrer, Eventplaner und alle, die Sitzpläne benötigen.",
        "alternateName": "Sitzplan Digital",
        "audience": {
          "@type": "Audience",
          "audienceType": [
            "Schüler",
            "Lehrer",
            "Teacher"
          ]
        },
        "author": {
          "@type": "Person",
          "name": "Carlo von Reyher",
          "url": "https://vonreyher.com",
          "sameAs": "https://www.wikidata.org/wiki/Q99570403"
        },
        "contributor": {
          "@type": "Organization",
          "name": "vonReyher.Media",
          "url": "https://vonreyher.media"
        },
        "copyrightHolder": {
          "@type": "Person",
          "name": "Carlo von Reyher"
        },
        "copyrightYear": "2023",
        "funder": {
          "@type": "Person",
          "name": "Thomas Roßwog"
        },
        "inLanguage": "German",
        "text": "Erstellen Sie schnell und einfach Sitzpläne für Ihre Veranstaltungen. Perfekt für Lehrer, Eventplaner und alle, die Sitzpläne benötigen.",
        "thumbnailUrl": "https://stizplandigital.de/ImageThumbnailAppSitzplanDigital.png",
        "version": "1.0.0"
      })}
        </script>
      </Helmet>
      <div>
        Du kannst jetzt auch deinen Sitzplan einfach als PDF exportieren.
      </div>

      <Form
        onFinish={handleSubmit}
        layout="vertical"
        style={{
          marginTop: 50
        }}
      >
        <Form.Item label="Klasse: ">
          <Input
            value={title}
            onChange={handleTitleChange}
            placeholder="..."
          />
        </Form.Item>
        <Form.Item label="Datum: ">
          <Input
            value={version}
            onChange={handleVersionChange}
            placeholder="..."
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Jetzt PDF erstellen
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
