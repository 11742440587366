// This is how the highest Data Object looks

const Data = {
    students: [
        {
            name: "Tobias",
            SitzplatzId: 12,
        },
        {
            name: "David",
            SitzplatzId: 9,
        },
        {
            name: "Carlo",
            SitzplatzId: 10,
        },
        {
            name: "Thomas",
            SitzplatzId: 3,
        },
        {
            name: "Baumai",
            SitzplatzId: 2,
        },
        {
            name: "Esswald",
            SitzplatzId: 4,
        },
        {
            name: "Georg",
            SitzplatzId: 8,
        }
    ],
    layout: {
        x: 12,
        y: 9
    }
}

export default Data;