import { Modal} from 'antd';
import {ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;


export default function showConfirmModal(title, description) {
    return new Promise((resolve, reject) => {

        confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            content: description,
            okText: 'Ja',
            okType: 'danger',
            cancelText: 'Nein',
            onOk() {
                resolve('OK');
            },
            onCancel() {
                reject(Error('Cancel'));
            },
        });
    });

};