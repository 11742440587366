

export function CreateURLEnding(data) {

    // FYI --> ~ bedeutet ein neues Objekt wird geöffnet _ schießt das Objekt wieder - bedeutet nächstes Element (im Array)
    var urlstring = '';  // Startet den Schüler Teil

    for (let index = 0; index < data.students.length; index++) {
        const element = data.students[index];
        urlstring = urlstring + element.name + '_' + element.SitzplatzId;
        if (index < data.students.length-1) {
            urlstring = urlstring + '-';
        }
    }
    // - --> Beendet den Schüler
    // Klassenraum Hinzufügen

    urlstring = urlstring + '_K~';  // Startet den Klassenraum Teil
    // X-Größe
    urlstring = urlstring + data.klassenraum.x;
    // Y-Größe
    urlstring = urlstring + '-' + data.klassenraum.y;


    return encodeURIComponent(urlstring);
    
}

export function EncodeURLEnding(urlending) {

    // var stringfromURL = 'Thomas_0-Carlo_1-Tom_2-Noah_3-Test_4_K~12-9'; //Format..

    // Creating normal String
    var stringfromURL = decodeURIComponent(urlending);

    var returnObject = {
        "students": [
        ],
        "klassenraum": {
            "x": 12,
            "y": 9
        }
    };
    // Creating Object from String


    // Find the Character Position from the Classroom Start

    var TwoParts = stringfromURL.split('_K~');


    // Klassenraum:
    var size = TwoParts[1].split('-');


    returnObject.klassenraum.x = parseInt(size[0]);
    returnObject.klassenraum.y = parseInt(size[1]);

    // Schueler:

    var Students = TwoParts[0].split('-');
    
    Students.forEach(element => {
        var StudentInformation = element.split('_');
        returnObject.students.push({SitzplatzId: parseInt(StudentInformation[1]), name: StudentInformation[0]})
    });

    console.log(returnObject);
    

    return returnObject;
}

export default function UpdateURL(data) {
    var newURLending = "?" + CreateURLEnding(data);
    window.history.replaceState(null, null, newURLending);
}