import { createSlice } from "@reduxjs/toolkit"

const initialState = [
    // {
    //     name: "Tobias",
    //     SitzplatzId: 12,
    // },
    // {
    //     name: "David",
    //     SitzplatzId: 9,
    // },
    // {
    //     name: "Carlo",
    //     SitzplatzId: 10,
    // },
    // {
    //     name: "Thomas",
    //     SitzplatzId: 3,
    // },
    // {
    //     name: "Tom",
    //     SitzplatzId: 2,
    // },
    // {
    //     name: "Noah",
    //     SitzplatzId: 4,
    // },
    // {
    //     name: "Georg",
    //     SitzplatzId: 8,
    // }
]

export const studentsSlice = createSlice({
    name: 'Students',
    initialState,
    reducers: {
        addStudent: (state, action) => {
            state.push({name: action.payload.name, SitzplatzId: action.payload.SitzplatzId});
        },
        deleteStudentBySitzplatzId: (state, action) => {
            var indexInOriginalArray = state.findIndex((element) => element.SitzplatzId === action.payload.SitzplatzId);
            state.splice(indexInOriginalArray, 1);

        },
        updateStudentById: (state, action) => {
            // gets a Payload with SitzplatzId, name
            if (action.payload.name.length > 16) {
                throw new Error('Dies ist nicht möglich, der Name ist zu lang.')
            }            
            // Add fehlende Infos to New User Object 
            var newUserData = {
                name: action.payload.name,
                SitzplatzId: action.payload.SitzplatzId,
            }
            
            // Integrate Data in the Array
            var indexInOriginalArray = state.findIndex((element) => element.SitzplatzId === action.payload.SitzplatzId);
            
            state = state.splice(indexInOriginalArray, 1, newUserData);
        },
        changeStudentSitzplatzIdFromOldToNew: (state, action) =>{
            //Payload: SitzplatzIdOld, SitzplatzIdNeu
            // update the Database
            var index = state.findIndex((element) => element.SitzplatzId === parseInt(action.payload.SitzplatzIdOld));
            if (index == null) {
                throw new Error('Anscheinend wurde kein Element gefunden.')
            }
            state[index].SitzplatzId = parseInt(action.payload.SitzplatzIdNeu);
        },
        updateAllStudentsData: (state, action) => {
            // Add all the Students from url to State
            action.payload.map((element, index) => {
                state[index] = element;
            })
        }
    }
})



export const { addStudent, deleteStudentBySitzplatzId, updateStudentById, changeStudentSitzplatzIdFromOldToNew, updateAllStudentsData } = studentsSlice.actions

export default studentsSlice.reducer